import { Routes } from '@angular/router';
import { RoutesPath } from '@shared/constants';
import { storeLoggedGuard } from '@shared/guards';
import { menuDisplayedResolve } from '@shared/resolvers';

export const APP_ROUTES: Routes = [
  {
    path: RoutesPath.LOGIN_STORE,
    resolve: { menu: menuDisplayedResolve },
    loadComponent: () =>
      import('@pages/login-store-page/login-store-page.component').then((m) => m.LoginStorePageComponent),
  },
  {
    path: RoutesPath.PROMO,
    resolve: { menu: menuDisplayedResolve },
    canActivate: [storeLoggedGuard],
    loadComponent: () => import('@pages/promotion-page/promotion-page.component').then((m) => m.PromotionPageComponent),
  },
  {
    path: RoutesPath.LOGIN_KITCHENER,
    resolve: { menu: menuDisplayedResolve },
    canActivate: [storeLoggedGuard],
    loadComponent: () =>
      import('@pages/login-kitchener-page/login-kitchener-page.component').then((m) => m.LoginKitchenerPageComponent),
  },
  {
    path: RoutesPath.CUSTOMER,
    resolve: { menu: menuDisplayedResolve },
    canActivate: [storeLoggedGuard],
    loadComponent: () =>
      import('@pages/customer-search-page/customer-search-page.component').then((m) => m.CustomerSearchPageComponent),
  },
  {
    path: RoutesPath.HOUSEHOLD_COMPOSITION,
    resolve: { menu: menuDisplayedResolve },
    canActivate: [storeLoggedGuard],
    loadComponent: () =>
      import('@pages/household-composition-page/household-composition-page.component').then(
        (m) => m.HouseholdCompositionPageComponent
      ),
  },
  {
    path: RoutesPath.DASHBOARD,
    resolve: { menu: menuDisplayedResolve },
    canActivate: [storeLoggedGuard],
    loadChildren: () => import('@pages/dashboard-page/dashboard-page-routes').then((m) => m.DASHBOARD_ROUTES()),
  },
  {
    path: '**',
    redirectTo: RoutesPath.LOGIN_STORE,
  },
];
