import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RoutesPath } from '@shared/constants';
import { AuthService } from '@shared/services/auth/auth.service';

export const storeLoggedGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  if (authService.storeLogged() && authService.isAuthenticated()) {
    return true;
  } else {
    void router.navigate([RoutesPath.LOGIN_STORE]);
    return false;
  }
};
