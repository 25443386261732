import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NavigationEnd, provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { EDecouverteService } from '@api/endpoints/edecouverte';
import { StoreCommonService } from '@api/endpoints/store-common';
import { InterceptorModule } from '@api/interceptors';
import { RoutesPath } from '@shared/constants';
import { INDEXED_DB_CONFIG, IndexedDbService } from '@shared/services';
import { AppInterfaceService } from '@shared/services/app-interface';
import { AuthService } from '@shared/services/auth/auth.service';
import { LogsService } from '@shared/services/logs';
import { default as packageInfo } from 'package.json';
import { filter, forkJoin, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { APP_ROUTES } from './app.routes';

registerLocaleData(localeFr, 'fr');

function initializeApp(
  indexedDbService: IndexedDbService,
  appInterfaceService: AppInterfaceService,
  authService: AuthService,
  storeCommonService: StoreCommonService,
  eDecouverteService: EDecouverteService,
  router: Router
): () => Observable<boolean[]> {
  return () =>
    indexedDbService
      .connectToDB()
      .pipe(
        mergeMap(() => {
          const isAuthenticated = authService.isAuthenticated();
          const loadAppInterface = appInterfaceService.loadAppInterface();
          const loadStoreLogged = authService.loadStoreLogged();
          const loadStoreCommon = storeCommonService.storeCommonSaved();
          const loadKitchenerLogged = authService.loadKitchenerLogged();
          const loadEdecSaved = eDecouverteService.loadEdecSaved();
          const mandatoryLoading = [
            isAuthenticated,
            loadAppInterface,
            loadStoreLogged,
            loadStoreCommon,
            loadKitchenerLogged,
            loadEdecSaved,
          ];
          return forkJoin(mandatoryLoading);
        })
      )
      .pipe(
        tap(([isAuthenticated, appInterface, storeLogged, storeCommon, kitchenerLogged, edecSaved]) => {
          LogsService.debug(`🤖 Informations retrieval`, {
            isAuthenticated,
            appInterface,
            storeLogged,
            storeCommon,
            kitchenerLogged,
            edecSaved,
          });
          if (!isAuthenticated) {
            authService.logout();
            void router.navigate([`./${RoutesPath.LOGIN_STORE}`]);
          } else if (!appInterface || !storeLogged || !storeCommon) {
            void router.navigate([`./${RoutesPath.LOGIN_STORE}`]);
          } else if (!kitchenerLogged) {
            void router.navigate([`./${RoutesPath.PROMO}`]);
          } else if (!edecSaved) {
            void router.navigate([`./${RoutesPath.CUSTOMER}`]);
          } else {
            router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
              const currentRoute = event.urlAfterRedirects;
              if (
                ['/', `/${RoutesPath.LOGIN_STORE}`, `/${RoutesPath.PROMO}`, `/${RoutesPath.CUSTOMER}`].includes(
                  currentRoute
                )
              ) {
                const householdComposition = eDecouverteService.eDecouverte().householdComposition;
                if (householdComposition && householdComposition.items?.length > 0) {
                  void router.navigate([`./${RoutesPath.YOUR_PROJECT}`]);
                } else {
                  void router.navigate([`./${RoutesPath.HOUSEHOLD_COMPOSITION}`]);
                }
              }
            });
          }
        })
      );
}

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, InterceptorModule),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideRouter(APP_ROUTES, withComponentInputBinding()),
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [IndexedDbService, AppInterfaceService, AuthService, StoreCommonService, EDecouverteService, Router],
      multi: true,
    },
    {
      provide: INDEXED_DB_CONFIG,
      useValue: [packageInfo.name, 1], // [IndexDB database name, Database version]
    },
  ],
};
