import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HeadService {
  readonly #document = inject(DOCUMENT);

  addPreconnectLink(link: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const linkElement = this.#document.createElement('link');
      linkElement.rel = 'preconnect';
      linkElement.href = link;
      linkElement.onload = () => {
        observer.next(true);
        observer.complete();
      };
      linkElement.onerror = () => {
        observer.next(false);
        observer.complete();
      };
      this.#document.head.appendChild(linkElement);
    });
  }
}
