import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { HeaderService } from '@components/header/services';
import { RoutesPath } from '@shared/constants';

export const menuDisplayedResolve: ResolveFn<boolean> = (route: ActivatedRouteSnapshot) => {
  const routeUrl = route.url[0]?.path ?? '';
  const headerService = inject(HeaderService);
  if ([RoutesPath.LOGIN_STORE, RoutesPath.LOGIN_KITCHENER].includes(routeUrl)) {
    // JUST LOGO
    headerService.setDisplayed({
      user: false,
      shadow: false,
      header: true,
      menu: false,
    });
  } else if ([RoutesPath.PROMO, RoutesPath.HOUSEHOLD_COMPOSITION].includes(routeUrl)) {
    // NO DISPLAY
    headerService.setDisplayed({
      user: false,
      shadow: false,
      header: false,
      menu: false,
    });
  } else if ([RoutesPath.CUSTOMER].includes(routeUrl)) {
    // NO DISPLAY
    headerService.setDisplayed({
      user: true,
      shadow: true,
      header: true,
      menu: false,
    });
  } else {
    // FULL HEADER
    headerService.setDisplayed({
      user: true,
      shadow: true,
      header: true,
      menu: true,
    });
  }
  return true;
};
