import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderComponent } from '@components/header';
import { environment } from '@environments/environment';
import { AppInterfaceService } from '@shared/services/app-interface';
import { HeadService } from '@shared/services/head';
import { LogsService } from '@shared/services/logs';
import { take } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [LogsService],
  imports: [RouterOutlet, HeaderComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  readonly appInterfaceLoaded = inject(AppInterfaceService).appInterfaceLoaded;
  readonly #headService = inject(HeadService);

  constructor() {
    inject(LogsService).updateLogLevelFromSession();
    try {
      const url = new URL(environment.API.url).origin;
      this.#headService.addPreconnectLink(url).pipe(take(1)).subscribe();
    } catch (e) {
      LogsService.warn(`Problem adding url in preconnect`);
    }
  }
}
